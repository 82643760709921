<script context="module">
	export async function load({ url }) {
		const slug = url.pathname;

		// If the slug starts with /api/ return the original response and body back
		if (slug.startsWith('/api/')) {
			return { status: 404, body: { error: 'Not Found' } };
		}

		return {
			props: { slug },
		};
	}
</script>

<div class="w-100vw h-100vh content-center bg-gray-50" style="height: 100vh; width: 100vw;">
	<div class="flex flex-col gap-6 items-center justify-center h-full text-gray-400">
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				style="width: 100px; height: 100px;"
				stroke-width="2"
			>
				<path d="M15 19h-10a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v2.5" />
				<path d="M19 22v.01" />
				<path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" />
			</svg>
		</div>
		<h1 class="text-3xl font-bold text-gray-400">Not Found</h1>
		<div class="text-xl text-gray-400">The page you're looking for doesn't exist.</div>
		<div>
			<a href="/" class="text-xl text-link">Back to Home</a>
		</div>
	</div>
</div>
